import { React, useState, useEffect } from 'react';
import { Card, CardContent, Chip, Typography, IconButton, Menu, Button, TextField, InputLabel, Select, MenuItem, FormControl, Stepper, Step, StepLabel, StepIcon, StepConnector, TimelineDot } from '@mui/material';
import axios from "axios";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import fileIcon from '../../assets/img/fileIcon.svg';
import SgLogo from '../../assets/img/SgLogo.png';
import sglogo from '../../assets/img/sg-logo.png';
import { useNavigate } from "react-router-dom";
import configParam from '../../config';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { reportboolean, selectedActionTakenByState, selectAllActionState, Comments } from '../../recoil/atoms';
import { useRecoilState } from 'recoil';
import ExcelJS from 'exceljs';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const access_token = localStorage.getItem("accesstoken");

const AnnualReportCard = ({ reportData, onEditClick, onDeleteClick, setLoadingpdf }) => {


  const [, setnextReport] = useRecoilState(reportboolean)
  const [menuAnchor, setMenuAnchor] = useState(null);

  const [userDet, setUserDet] = useState({});
  const [plantName, setPlantName] = useState('');

  const [loading, _] = useState(false)
  const navigate = useNavigate();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const closeInfoModal = () => setInfoModalOpen(false);
  const [actionTakenModalOpen, setActionTakenModalOpen] = useState(false);
  const [selectedActionDate, setSelectedActionDate] = useState('');
  const [actionCommentselect, setActionCommentSelect] = useState('')
  const [actionComment, setActionComment] = useRecoilState(Comments);
  const [, setErrorMessageActionDate] = useState(null);
  const [selectedActionTakenBy, setSelectedActionTakenBy] = useRecoilState(selectedActionTakenByState); // To store the selected options
  const [selectAllAction, setSelectAllAction] = useRecoilState(selectAllActionState); // To handle "Select All" checkbox
  const [actionTakenByOptions, setActionTakenByOptions] = useState([]);
  const [actionHistory, setActionHistory] = useState([]);
  const moment = require('moment');
  const ApiURL = configParam.API_URL;

  const cardStyle = {

    width: '100%', // Set the desired width for the cards

    height: '100%',
    marginBottom: '10px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: '18px', // Add margin to the right of each card

  };
  const cardMobileStyle = {

    width: '100%', // Set the desired width for the cards

    height: '100%',
    marginBottom: '10px',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginRight: '18px', // Add margin to the right of each card

  };

  const contentStyle = {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    overflow: 'hidden'

  };
  const contentStyleMobile = {
    // Styles for mobile view
    display: 'flex',

    flexDirection: 'column',
    justifyContent: 'space-between', // Adjust as needed
    flex: 1,
    overflow: 'hidden'
    // Additional styles for mobile if needed
  };
  const topRightCornerStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: '15px',
  };
  

  const isMobileView = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    // Fetch plant reports data when the component mounts
    if (reportData.plant_id) {
      getPlantReports(reportData.plant_id);

    }
  }, [reportData.plant_id]);
  useEffect(() => {
    let data = localStorage.getItem("user");
    let User = JSON.parse(data);
    setUserDet(User);
  }, []);
  useEffect(() => {
  
    if (loading) {
      navigate('/reports');
      window.location.reload();


    }
  }, [loading]);


  const handleInfoClick = () => {
    const reportId = reportData.id;
    let url = ApiURL + `/get_action_data/${reportId}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,

        }
      }
      )
      .then((response) => {
        const actionHistoryWithFormattedDate = response.data.map((item) => {
          const dateTimeString = item.action_date || ''; // Use default value if date is invalid
         
          const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");
          const increasedDate = moment(formattedDate, "YYYY-MM-DD").add(1, 'days');
         
          // Format the increased date back to the desired format
          const formattedIncreasedDate = dateTimeString.endsWith("T00:00:00.000Z") ? formattedDate : increasedDate.format("YYYY-MM-DD");
         

          // Add the formatted dates to the item
          return { ...item, formattedDate, formattedIncreasedDate };
        });

        setActionHistory(actionHistoryWithFormattedDate);


        
      })
      .catch((error) => {
        setActionHistory([]);
      });
    setInfoModalOpen(true);


  };

  const handleOpenMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };
  const handleEditClick = () => {
    onEditClick(reportData);

    setnextReport(false);
  };


  const MAX_RETRIES = 10; // Set maximum retry attempts

  const fetchWithRetry = async (url, options, retries = 0) => {
    try {
      const response = await axios.post(url, options, {
        headers: {
          Authorization: `Bearer ${access_token}`,

        }
      }
      );
      return response;
    } catch (error) {
      if (retries < MAX_RETRIES && error.response.status !== 200) {
       
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust delay as needed
        return fetchWithRetry(url, options, retries + 1);
      } else {
        throw error; // Re-throw error if exceeding retries or status code is not the issue
      }
    }
  };

  const handleExcelClick = async () => {
    try {
      const response = await axios.get(ApiURL + `/api/reports/${reportData.id}/excel-pdf`, {
        headers: {
          Authorization: `Bearer ${access_token}`,

        }
      }
      );
      const arealist = response.data.arealist.flat();
      const reportName = response.data.reportname[0].report_name;
      

      // Create a new Excel workbook and worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');

      // Add headers to the worksheet (assuming 'arealist' is an array of objects)
      const headers = Object.keys(arealist[0]);
      const headerRow = worksheet.addRow(headers);

      // Set font style to bold for the header row
      headerRow.font = { bold: true };


      let prevArea = '';
      let startMergeRow = 2; // Start from the second row (after the header row)

      // Add data rows to the worksheet
      arealist.forEach((row, index) => {
        const values = headers.map((header) => row[header]);

        // Check if the current area is the same as the previous one
        if (values.length > 0 && values[0] === prevArea) {
          // If it's the same area, continue to the next row
          worksheet.addRow(values);
        } else {
          // If it's a new area, merge cells for the previous area
          if (startMergeRow < index + 1) {
            worksheet.mergeCells(`A${startMergeRow}:A${index + 1}`);

          }

          // Start a new merge for the current area
          startMergeRow = index + 2;
          worksheet.addRow(values);
          prevArea = values[0];
        }
      });

      // Merge cells for the last area if needed
      if (startMergeRow < arealist.length + 1) {
        worksheet.mergeCells(`A${startMergeRow}:A${arealist.length + 1}`);

      }

      worksheet.getColumn('A').alignment = { horizontal: 'center', vertical: 'center' };

      const niColumn = worksheet.getColumn('D'); // Assuming Ni.% is the 4th column (D)

      niColumn.eachCell((cell, rowNumber) => {
        if (rowNumber > 1) {
          const value = cell.value;

          if (value > 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFF0000' }, // Red color
            };
          } else if (value >= 0.1 && value < 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFA500' }, // Orange color
            };
          } else if (value >= 0.05 && value < 0.1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFF8D568' }, // Custom color
            };
          } else {
            // Default to white if none of the above conditions are met
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFFFFF' },
            };
          }
        }
      });


      // Create a buffer from the workbook
      const buffer = await workbook.xlsx.writeBuffer();

      // Download the Excel file
      handleExcelDownload(buffer, reportName);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleExcelDownload = (buffer, reportName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${reportName}.xlsx`;
    link.click();
  };

  const handleExcelcustomClick = async () => {
    try {
      const response = await axios.get(ApiURL + `/api/reportz_custom/${reportData.id}/excel-pdf`, {
        headers: {
          Authorization: `Bearer ${access_token}`,

        }
      }
      );
      const arealist = response.data.arealist.flat();
      const reportName = response.data.reportname[0].report_name;
    

      // Create a new Excel workbook and worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sheet 1');

      // Add headers to the worksheet (assuming 'arealist' is an array of objects)
      const headers = Object.keys(arealist[0]);
      const headerRow = worksheet.addRow(headers);

      // Set font style to bold for the header row
      headerRow.font = { bold: true };


      let prevArea = '';
      let startMergeRow = 2; // Start from the second row (after the header row)

      // Add data rows to the worksheet
      arealist.forEach((row, index) => {
        const values = headers.map((header) => row[header]);

        // Check if the current area is the same as the previous one
        if (values.length > 0 && values[0] === prevArea) {
          // If it's the same area, continue to the next row
          worksheet.addRow(values);
        } else {
          // If it's a new area, merge cells for the previous area
          if (startMergeRow < index + 1) {
            worksheet.mergeCells(`A${startMergeRow}:A${index + 1}`);

          }

          // Start a new merge for the current area
          startMergeRow = index + 2;
          worksheet.addRow(values);
          prevArea = values[0];
        }
      });

      // Merge cells for the last area if needed
      if (startMergeRow < arealist.length + 1) {
        worksheet.mergeCells(`A${startMergeRow}:A${arealist.length + 1}`);

      }

      worksheet.getColumn('A').alignment = { horizontal: 'center', vertical: 'center' };

      const niColumn = worksheet.getColumn('D'); // Assuming Ni.% is the 4th column (D)

      niColumn.eachCell((cell, rowNumber) => {
        if (rowNumber > 1) {
          const value = cell.value;

          if (value > 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFF0000' }, // Red color
            };
          } else if (value >= 0.1 && value < 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFA500' }, // Orange color
            };
          } else if (value >= 0.05 && value < 0.1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFF8D568' }, // Custom color
            };
          } else {
            // Default to white if none of the above conditions are met
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFFFFFFF' },
            };
          }
        }
      });


      // Create a buffer from the workbook
      const buffer = await workbook.xlsx.writeBuffer();

      // Download the Excel file
      handleExcelcustomDownload(buffer, reportName);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleExcelcustomDownload = (buffer, reportName) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${reportName}.xlsx`;
    link.click();
  };
  const handleViewsClick = async () => {
    setLoadingpdf(true);
    const response = await axios.get(ApiURL + `/api/reports/${reportData.id}/view-pdf`, {
      headers: {
        Authorization: `Bearer ${access_token}`,

      }
    }
    );
    const data = response.data;
 
    const imagearray = []
    for (const subArray of data.arealist) {
      if (subArray.length === 0) {
        // If subArray is empty, move on to the next iteration
       
        continue;
      }

    




      for (const row of subArray) {


        const currentTrial = row.path;
        const parts = currentTrial.split('/');
        const valuesAfterSlash = parts[1];

        const imageUrl = ApiURL + `/show_images`;
        const a = await fetchWithRetry(imageUrl, {
          imageName: valuesAfterSlash,
        })
        const fileExtension = valuesAfterSlash.split('.').pop();
        const updatedresponse = `data:image/${fileExtension};base64,` + a.data;
        imagearray.push({ image: updatedresponse, path: currentTrial });


      }


    }
   

    let entireHTMLContent = await handleViewClick(data, imagearray)
    setLoadingpdf(false)
    const commonHeader = `
 <header>
 <div class="header">
 <!-- Add Saint-Gobain logo here in the header -->
 
 </div>
 </header>
`;

    document.body.innerHTML = commonHeader + entireHTMLContent; // Set content for printing
    // <img src="${imagePath}"  alt="Saint-Gobain Logo" />
    
    let style = document.createElement('style');
    style.innerHTML = `
@media print {
 img {
   display: block; // Ensure images are displayed
   max-width: 100%; // Prevent image overflow
 }
 header > img {
   display: block
 }
}
`;
    document.head.appendChild(style);

  

    let printWindow = window.open('123', '_blank123');
  


    printWindow.document.open();
    // You can manipulate the document of printWindow here


    let template = `
<html>
 <head>
   <style>
   body {
     margin: 10px ;
     
 
   }

     /* Your CSS styles here */
     #header {
       position: fixed;
     opacity:0.2;
       top: 0;
       bottom:0;
       margin:auto;
       width:300px;
       left: 0;
       right: 0;
       text-align: center;
       padding: 10px;
       z-index: 1000;
       padding-bottom: 50px; 
     }
     @page {
       size: A4;
       padding-top: 100px; /* Adjust the width as needed */
     margin-bottom: 100px;
      
      /* Reset the top margin to respect the header */
     }
     #header img {
       /* Add styles for the image within the header here */
       max-width: 100%; /* Adjust the width as needed */
       max-height: 100%;
      
       padding: 10px;
      

     }
     #content {
       margin: 20px;
       
    
      
     }
     p {
       font-size: 16px; 
       font-family: Inter; 
       font-weight: 400;
       line-height: 1.5; /* Adjust line spacing as needed (1.5 is just an example) */
       letter-spacing: 0.5px;
       text-align: justify;
   }
   li{font-size: 16px; 
     font-family: Inter; 
     font-weight: 400;
     line-height: 1.5; /* Adjust line spacing as needed (1.5 is just an example) */
     letter-spacing: 0.5px;
     text-align: justify;
    
   }
   </style>
 </head>
 <body>

   <div id="content" >
     ${entireHTMLContent} <!-- Your existing content goes here -->
   </div>

 </body>
</html>
`;

   

    printWindow.document.write(template);

    printWindow.document.close();

    // // Wait for the content to load before printing

    printWindow.onload = function () {

      printWindow.print({ printBackground: true });
      window.location.reload()
      printWindow.close();
      setTimeout(() => {
        document.head.removeChild(style);
      }, 100);


    }

  }

  const handleClick = async () => {
    setLoadingpdf(true);
    const response = await axios.get(ApiURL + `/api/reportz_custom/${reportData.id}/view-pdf`, {
      headers: {
        Authorization: `Bearer ${access_token}`,

      }
    }
    );
    const data = response.data;
   
    const imagearray = [];
    for (const subArray of data.arealist) {
      if (subArray.length === 0) {
        // If subArray is empty, move on to the next iteration
        
        continue;
      }

    




      for (let i = 0; i < subArray.length; i++) {
        const row = subArray[i];

        const currentTrial = row.path;
        const parts = currentTrial.split('/');
        const valuesAfterSlash = parts[1];

        const imageUrl = ApiURL + `/show_images`;
        const a = await fetchWithRetry(imageUrl, {
          imageName: valuesAfterSlash,
        }
        )
        const fileExtension = valuesAfterSlash.split('.').pop();
        const updatedresponse = `data:image/${fileExtension};base64,` + a.data;
        imagearray.push({ image: updatedresponse, path: currentTrial });

      }


    }
   

    let entireHTMLContent = await handleCustomClick(data, imagearray)
    setLoadingpdf(false);
    const commonHeader = `
 <header>
 <div class="header">
 <!-- Add Saint-Gobain logo here in the header -->
 
 </div>
 </header>
`;

    document.body.innerHTML = commonHeader + entireHTMLContent; // Set content for printing
    // <img src="${imagePath}"  alt="Saint-Gobain Logo" />

    let style = document.createElement('style');
    style.innerHTML = `
@media print {
 img {
   display: block; // Ensure images are displayed
   max-width: 100%; // Prevent image overflow
 }
 header > img {
   display: block
 }
}
`;
    document.head.appendChild(style);

   

    let printWindow = window.open('123', '_blank123');
   


    printWindow.document.open();
    // You can manipulate the document of printWindow here


    let template = `
<html>
 <head>
   <style>
   body {
     margin: 10px ;
     
 
   }

     /* Your CSS styles here */
     #header {
       position: fixed;
     opacity:0.2;
       top: 0;
       bottom:0;
       margin:auto;
       width:300px;
       left: 0;
       right: 0;
       text-align: center;
       padding: 10px;
       z-index: 1000;
       padding-bottom: 50px; 
     }
     @page {
       size: A4;
       padding-top: 100px; /* Adjust the width as needed */
     margin-bottom: 100px;
      
      /* Reset the top margin to respect the header */
     }
     #header img {
       /* Add styles for the image within the header here */
       width: 200px; /* Adjust the width as needed */
       height: 200px;
      
       padding: 10px;
      

     }
     #content {
       margin: 20px;
       
    
      
     }
     p {
       font-size: 16px; 
       font-family: Inter; 
       font-weight: 400;
       line-height: 1.5; /* Adjust line spacing as needed (1.5 is just an example) */
       letter-spacing: 0.5px;
       text-align: justify;
   }
   li{font-size: 16px; 
     font-family: Inter; 
     font-weight: 400;
     line-height: 1.5; /* Adjust line spacing as needed (1.5 is just an example) */
     letter-spacing: 0.5px;
     text-align: justify;
    
   }
   </style>
 </head>
 <body>

   <div id="content" >
     ${entireHTMLContent} <!-- Your existing content goes here -->
   </div>

 </body>
</html>
`;

   

    printWindow.document.write(template);

    printWindow.document.close();

    // // Wait for the content to load before printing

    printWindow.onload = function () {

      printWindow.print({ printBackground: true });
      window.location.reload()
      printWindow.close();
      setTimeout(() => {
        document.head.removeChild(style);
      }, 100);


    }

  }


  const handleViewClick = async (data, imagearray) => {
    setMenuAnchor(null);
   
    let originalContent = document.body.innerHTML; // Store original content





    let entireHTMLContent = '';
    entireHTMLContent += `
          <html>
          <head>
            <title> ${data.frep[0].reference}</title>
            <style>
              /* Define your CSS styles here for formatting */
              .title-container {
                text-align: right; /* Align the container to the right */
                padding: 10px;
              }
              .title {
                text-align: right;
              }
              
              body {
                margin-top: 30;
                font-size: 16px; 
                font-family: Inter; 
                font-weight: 400;
                word-wrap: break-word
                /* font-family: Arial, sans-serif; */
                margin: 0; /* Remove default margins */
                padding: 0;

              }

              .header {
                text-align: center;
                
                color: black;
                padding: 5px;
                margin: 0;
              }
              .header p {
                text-align: center; /* Center-align the text within the <p> element */
              }
           .header img {
                display: block;
                margin: 0 auto; /* Center the image horizontally */
                width: 200px; /* Adjust the width as needed */
                max-height: 200px; /* Adjust the height as needed */
              }
              .section-container {
                margin-top: 50px; /* Add a 10px top margin to create a gap */
              }
              .report-title {
                text-align: center;
                margin-top: -20px;
                margin-bottom: 0; /* Set margin-bottom to 0 */
                padding-bottom: 0;
              }
              .company {
                text-align: center;
              }
              .info-container {
                display: flex;
                flex-direction: column;
              }
              .info-item {
                margin-bottom: 10px;
                
              }
              .info-item-in {
                margin-bottom: 10px;
                display: flex;
              }
              .info-label {
                width: 200px; /* Adjust the width as needed */
                display: inline-block;
              }
              .info-label_in {
               
                margin-bottom: 5px;
              }
              .info-label_in + p {
                margin-top: 5px; /* Adjust the value as needed */
              }
              .info {
                margin-left: 10px;
              }
              .info_in {
                margin-left: 10px;
                white-space: pre-wrap;
                display: inline-block;
                max-width: 400px; 
              }
              .section {
                margin-top: 20px;
                padding: 10px;
                line-height: 20px;
                border: 1px solid #ddd;
              }
              .section-headings {
                text-align: left;
                padding: 10px;
                margin: 0;
              }
              .section-heading {
               
                font-size: 16px;
               
              }

              .section-content {
                font-size: 14px;
              }
              .next-page {
                page-break-before: always;
              
              }
              /* Add more styles as needed */


    

              .summary {
                height: 50vh; /* Set the height of the first page to cover the entire viewport height */
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
              .footer-content {
                position: absolute;
                bottom: 10;
                width: 100%;
                text-align: center;
              
                color: black;
                padding: 3px;
              }
              .footer-content img {
                max-width: 100px; /* Adjust the width as needed */
                max-height: 100px; /* Adjust the height as needed */
              }
              .summary-bar {
                margin-top: 100px;
                background-color: #F2F4F7; /* Grey background color */
                width: 100vw; /* Cover the entire width of the page */
                text-align: left;
                color: #000000;
                padding: 10px;
                margin: 0;
              }
              .summary-text {
                font-size: 20px;
                font-weight: bold;
              }
              .red {
                background-color: #FF0000;
              }
              .orange-row {
                background-color: #FFA500;
              }
              .oran-row {
                background-color: #FFA500;
                color: red;
              }
              .yellow-row {
                background-color: #F8D568;
              }
              .black-row {
                background-color: #FFFFFF;
              }
              .Vermilion {
                background-color: #e34234;
              }
              
             
            </style>
          </head>
          <body>
         

      
            
           

        <div class="header">
        <!-- Add company logo and address here -->
        <img src="${sglogo}" alt="Company Logo" />
        <p>Saint-Gobain India Pvt Ltd. (Research and Development) <br>
        IIT Madras Research Park, NO:1 FA, Phase-II, C block, Kanagam Road, Tharamani, Chennai-600 113, Tamil Nadu, India.</p>
      </div>
            
            
            
          </body>
          </html>
        `;
  

    entireHTMLContent += `
      
        
          <h1 class="report-title">${data.repdata[0].report_name}</h1>
        
        <div class="summary-bar">
          <div class="summary-text">Flashmemo:</div>
          </div>
          <p>Saint Gobain India Private Limited (Research and Development)</p>
          
          <div class="info-container">
            <div class="info-item-in">
              <span class="info-label">FROM:</span> <span class="info_in">${data.frep[0].from}</span>
            </div>
            <div class="info-item-in">
              <span class="info-label">TO:</span> <span class="info_in">${data.frep[0].to}</span>
            </div>
            <div class="info-item-in">
              <span class="info-label">COPY:</span> <span class="info_in">${data.frep[0].copy}</span>
            </div>
            <div class="info-item-in">
              <span class="info-label">DATE:</span> <span class="info_in">${data.frep[0].date}</span>
            </div>
            <div class="info-item-in">
              <span class="info-label">REFERENCE:</span> <span class="info_in">${data.frep[0].reference}</span>
            </div>
            <div class="info-item-in">
            <span class="info-label">CONFIDENTIALITY:</span> <span class="info_in">${data.frep[0].confidentiality}</span>
          </div>
          </div>
       
        
        `;

    entireHTMLContent += `
     
        <div class="summary-bar">
        <div class="summary-text">Title of the Memo: </div>
        </div>
    
        <p>${data.objective[0].title}</p>
        <div class="summary-bar">
        <div class="summary-text">N GIE: </div>
        </div>
        <p>${data.objective[0].n_gie}</p>
        <div class="summary-bar">
        <div class="summary-text">Objective: </div>
        </div>
    
        <p>${data.objective[0].objective}</p>
        <div class="summary-bar">
        <div class="summary-text">Main Conclusion: </div>
        </div>
        <p>${data.objective[0].main_conclusion}</p>
        <div class="summary-bar">
        <div class="summary-text">Next Step: </div>
        </div>
    
        <p>${data.objective[0].next_step}</p>
    

 <div class="summary-bar">
<div class="summary-text">SUMMARY</div>
</div>
<div class="section-headings">
<ol>

<li><div class="section-heading">Flashmemo</div></li>
<li><div class="section-heading">Objective</div></li>
<li><div class="section-heading">Introduction</div></li>
<li><div class="section-heading">Overview</div></li>
<li><div class="section-heading">Work Description</div></li>
<li><div class="section-heading">Result</div></li>
<li><div class="section-heading">Area Description</div></li>
<li><div class="section-heading">Conclusion</div></li>
</ol>
</div>
            <div class="summary-bar">
            <div class="summary-text">Introduction: </div>
            </div>
        
            <p>${data.repdata[0].introduction}</p>
            <div class="summary-bar">
            <div class="summary-text">Overview: </div>
            </div>
          
     
            <p>${data.repdata[0].overview}</p>

            <div class="summary-bar">
            <div class="summary-text">Work Description: </div>
            </div>
          
          
            <p>${data.repdata[0].workDescription}</p>
            <div class="summary-bar">
            <div class="summary-text"> Colours code %Ni  </div>
            </div>
            <p> According to the Ni% content, there is a Colours Code of priorities: </p>
            <table>
              <thead>
                <tr>
                  <th> %Ni </th>
                  <th>Priority</th>
                 
                </tr>
              </thead>
              <tbody>
              <tr>
              <td  class = "Vermilion"> > 1%</td>
              <td>1</td>
              </tr>
              <tr>
              <td  class = "oran-row" > 0.1% - 1% </td>
              <td>2</td>
              </tr>
              <tr>
              <td  class = "yellow-row" > 0.05% - 0.1% </td>
              <td> Acceptable </td>
              </tr>
              <tr>
              <td  class = "black-row" > <0.05% </td>
              <td> OK </td>
              </tr>
              </tbody>
            </table>
            <p>
            <strong style="text-decoration: underline; ">
           Not only Ni % define an action plan
            </strong>
            (replacement or protection the high Nickel content equipment/parts), but
            <strong style="text-decoration: underline; ">
           also abrasion level for each piece (g/year).
             </strong>
        </p>

        <div class="summary-bar">
        <div class="summary-text">Result: </div>
        </div>
      
 
        <p>${data.repdata[0].result}</p>
      
            <div class="summary-bar">
            <div class="summary-text">Area Description: </div>
            </div>
          
           
        `;
    let sectionCounter = 0;


    for (const subArray of data.arealist) {
      if (subArray.length === 0) {
        // If subArray is empty, move on to the next iteration
        continue;
      }
      sectionCounter++;
    

      entireHTMLContent += `
          <h3>
    <strong style="text-decoration: underline; text-transform: uppercase;">
    ${sectionCounter}. ${subArray[0].area}
    </strong>
</h3>

          
      
            <p>${data.flashData[subArray[0].id]}</p>
          
          `;

      let tbodyContentt = ''; // Initialize tbody content for the section

      const calculateRowspan = (currentIndex) => {
        let rowspan = 1;
        while (currentIndex + rowspan < subArray.length &&
          subArray[currentIndex].area === subArray[currentIndex + rowspan].area) {
          rowspan++;
        }
        return rowspan;
      };
      let rowIndex = 0
      for (const row of subArray) {
        
        let rowClass = ''; // Initialize an empty class string

        if (parseFloat(row.ni) > 1) {
          rowClass = 'red';
        } else if (parseFloat(row.ni) >= 0.1 && parseFloat(row.ni) < 1) {
          rowClass = 'orange-row'; // Orange background for ni >= 0.1 and < 1
        } else if (parseFloat(row.ni) >= 0.05 && parseFloat(row.ni) < 0.1) {
          rowClass = 'yellow-row'; // Yellow background for ni >= 0.05 and < 0.1%
        } else {
          rowClass = 'black-row'; // Black background for ni < 0.05%
        }


        //  const reportimg= getImageData(imagePath)
        //  console.log("reportimghandleviewsclcik",reportimg)
        //  const updatedresponse=`data:image/jpeg;base64,`+reportimg.data;
        const imageSize = 200;

        const rowspan = calculateRowspan(rowIndex);
        const match = imagearray.find(obj2 => obj2.path === row.path);
       
        // Generate table rows
        tbodyContentt += `
              <tr>
              ${rowIndex === 0 ? `<td  rowspan="${rowspan}">${row.area}</td>` : ''}
    <td class="${rowClass}">${row.zone}</td>
    <td class="${rowClass}">${row.xrf_number}</td>
    <td class="${rowClass}">${row.ni}</td>
    <td class="${rowClass}">${row.remark}<br>
    ${row.comments}</td>
    <td style="width: ${imageSize}px; height: ${imageSize}px; overflow: hidden;">
    <img src="${match.image}" alt="image" style="width: 100%; height: auto;">
  </td>
</tr>`;
        rowIndex++;
      };


      // Use the tbodyContent in your HTML
      const areatableHTML = `
          <html>
          <head>
            <style>
            img {
              max-width: 100%;
              max-height: 100%;
            }
              table {
                width: 100%;
                border-collapse: collapse;
              }
              .red {
                background-color: #e34234;
              }
              .orange-row {
                background-color: #FFA500;
              }
              .yellow-row {
                background-color: #F8D568;
              }
              .black-row {
                background-color: #FFFFFF;
              }
              th, td {
                border: 1px solid black;
                padding: 0;
                text-align: center;
              }
            </style>
          </head>
          <body>
            <table>
              <thead>
                <tr>
                  <th>Area</th>
                  <th>Zone</th>
                  <th>XRF Ref.</th>
                  <th>Ni%</th>
                  <th>Remark <br> Comments</th>
                  <th>Images</th>
                </tr>
              </thead>
              <tbody>
                ${tbodyContentt}
              </tbody>
            </table>
          </body>
          </html>
          `;

      entireHTMLContent += areatableHTML;
    };

    // Now, 'entireHTMLContent' contains the HTML code with merged rows for the same area.
    



    entireHTMLContent += `

                        <div class="summary-bar">
                        <div class="summary-text">Conclusion: </div>
                        </div>

           
          
            
         

        
           
           
          `;

    const ConclusiontableHTML = `
          <html>
          <head>
            <style>
              table {
                width: 100%;
                border-collapse: collapse;
              }.red {
                background-color: #e34234;
              } .orange-row {
                background-color: #FFA500;
              }
              .yellow-row {
                background-color: #F8D568;
              }
              .black-row {
                background-color: #FFFFFF;
              }
              th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: center;
              }
              table {
                margin-bottom: 20px; /* Add space after the table */
              }
            </style>
          </head>
          <body>
            <h4>Relevant values of Nickel</h4>
            <table>
              <thead>
                <tr>
                  <th>Priority</th>
                  <th>% of Ni</th>
                  <th>No. of points</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                <td>1</td>
                <td>>1.0%</td>
                <td class="red">${data.greater}</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>0.1-1.0%</td>
                  <td class="orange-row">${data.inBetween}</td>
                </tr>
                <tr>
                <td>Acceptable</td>
                <td>0.05-0.1%</td>
                <td class="yellow-row">${data.inBet}</td>
                </tr>
                <tr>
                <td>OK</td>
                <td><0.05%</td>
                <td class="black-row">${data.lesser}</td>
                </tr>
              </tbody>
            </table>
          </body>
          </html>
        `;

    entireHTMLContent += ConclusiontableHTML;

    const htmlContent = `<!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Simple Pie Chart</title>
          <style>
            canvas {
              display: block;
              margin: 10px auto; /* Reduced margin */
            }
            .legend {
              display: flex;
              justify-content: center;
              margin-top: 10px;
            }
            .legend-item {
              display: flex;
              align-items: center;
              margin-right: 20px;
            }
            .legend-item span {
              display: inline-block;
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
          </style>
        </head>
        <body>
          <canvas id="myPieChart" width="200" height="200"></canvas>
          <div class="legend" id="legend"></div>
        
          <script>
            // Get the canvas element and its context
            var canvas = document.getElementById('myPieChart');
            var ctx = canvas.getContext('2d');
        
            // Define data for the pie chart
            var data = [${data.greater}, ${data.inBetween}, ${data.inBet}, ${data.lesser}]; // Values for each segment
            var colors = ['#e34234', '#FFA500', '#F8D568', '#4c4cff']; // Colors for each segment
            var legends = ['>1.0%', '0.1-1.0%', '0.05-0.1%', '<0.05%']; // Legends for each segment
        
            // Function to draw a pie chart
            function drawPieChart(data, colors, legends, context, canvas) {
              var total = data.reduce((acc, value) => acc + value, 0);
              var startAngle = 0;
              var centerX = canvas.width / 2;
              var centerY = canvas.height / 2;
              var radius = Math.min(canvas.width, canvas.height) / 2;
        
              for (var i = 0; i < data.length; i++) {
                var sliceAngle = (2 * Math.PI * data[i]) / total;
        
                // Draw the pie slice
                context.fillStyle = colors[i];
                context.beginPath();
                context.moveTo(centerX, centerY);
                context.arc(centerX, centerY, radius, startAngle, startAngle + sliceAngle);
                context.closePath();
                context.fill();
        
                startAngle += sliceAngle;
              }
        
              // Draw legend below the chart
              var legendHTML = '';
              for (var i = 0; i < legends.length; i++) {
                legendHTML += '<div class="legend-item"><span style="background-color:' + colors[i] + '"></span>' + legends[i] + '</div>';
              }
              document.getElementById('legend').innerHTML = legendHTML;
            }
        
            // Call the function to draw the pie chart
            drawPieChart(data, colors, legends, ctx, canvas);
          </script>
        </body>
        </html>`;






    entireHTMLContent += htmlContent;

    entireHTMLContent += `
       
       <p>${data.repdata[0].conclusion}</p>
      `;

    let tbodyContent = '';

    // Iterate through the Reportarea data and generate table rows
    for (const rows of data.niData) {
      let rowClass = ''; // Initialize an empty class string

      if (parseFloat(rows.ni) > 1) {
        rowClass = 'red';
      } else if (parseFloat(rows.ni) > 0.1 && parseFloat(rows.ni) <= 1) {
        rowClass = 'orange-row'; // Orange background for ni >= 1% and < 1
      } else if (parseFloat(rows.ni) > 0.05 && parseFloat(rows.ni) <= 0.1) {
        rowClass = 'yellow-row'; // Yellow background for ni >= 0.05% and < 0.1%
      } else {
        rowClass = 'black-row'; // Black background for ni < 0.05%
      }
      tbodyContent += `
            <tr>
                <td>${rows.area}</td>
                <td>${rows.zone}</td>
                <td>${rows.xrf_number}</td>
                <td class="${rowClass}">${rows.ni}</td>

              </tr>
            `;
    }

    const tableHTML = `
            <html>
            <head>
              <style>
                table {
                  width: 100%;
                  border-collapse: collapse;
                }.red {
                  background-color: #e34234;
                } .orange-row {
                  background-color: #FFA500;
                }
                .yellow-row {
                  background-color: #F8D568;
                }
                .black-row {
                  background-color: #FFFFFF;
                }
                th, td {
                  border: 1px solid black;
                  padding: 8px;
                  text-align: center;
                }
                table {
                  margin-bottom: 20px; /* Add space after the table */
                }
                .summary-bar {
                  background-color: #f1f0f0; /* Grey background color */
                  width: 100vw; /* Cover the entire width of the page */
                  text-align: left;
                  color: #000000;
                  padding: 10px;
                  margin: 0;
                }
                .summary-text {
                  font-size: 20px;
                  font-weight: bold;
                }
              </style>
            </head>
            <body>
            <div class="summary-bar">
            <div class="summary-text">>1% Ni parts found:</div>
          </div>
             
              <p>There are ${data.niDatas} points found having Ni content >1%, presented below:</p>
              <table>
                <thead>
                  <tr>
      
                    <th>Area</th>
                    <th>Zone</th>
                    <th>XRF Ref.</th>
                    <th>Ni%</th>
              
                  </tr>
                </thead>
                <tbody>
                  ${tbodyContent}
                </tbody>
              </table>
            </body>
            </html>
          `;
    entireHTMLContent += tableHTML;

    let tbodyContents = '';

    // Iterate through the Reportarea data and generate table rows
    for (const rows of data.nibetData) {
      let rowClass = ''; // Initialize an empty class string

      if (parseFloat(rows.ni) > 1) {
        rowClass = 'red';
      } else if (parseFloat(rows.ni) > 0.1 && parseFloat(rows.ni) <= 1) {
        rowClass = 'orange-row'; // Orange background for ni >= 1% and < 1
      } else if (parseFloat(rows.ni) > 0.05 && parseFloat(rows.ni) <= 0.1) {
        rowClass = 'yellow-row'; // Yellow background for ni >= 0.05% and < 0.1%
      } else {
        rowClass = 'black-row'; // Black background for ni < 0.05%
      }
      tbodyContents += `
              <tr>
                <td>${rows.area}</td>
                <td>${rows.zone}</td>
                <td>${rows.xrf_number}</td>
                <td  class="${rowClass}">${rows.ni}</td>

              </tr>
            `;
    }


    const tableHTMLs = `
            <html>
            <head>
              <style>
                table {
                  width: 100%;
                  border-collapse: collapse;
                }.red {
                  background-color: #e34234;
                } .orange-row {
                  background-color: #FFA500;
                }
                .yellow-row {
                  background-color: #F8D568;
                }
                .black-row {
                  background-color: #FFFFFF;
                }
                th, td {
                  border: 1px solid black;
                  padding: 8px;
                  text-align: center;
                }
                table {
                  margin-bottom: 20px; /* Add space after the table */
                }
                .summary-bar {
                  background-color: #f1f0f0; /* Grey background color */
                  width: 100vw; /* Cover the entire width of the page */
                  text-align: left;
                  color: #000000;
                  padding: 10px;
                  margin: 0;
                }
                .summary-text {
                  font-size: 20px;
                  font-weight: bold;
                }
              </style>
            </head>
            <body>
            <div class="summary-bar">
            <div class="summary-text">>0.1% - 1% Ni parts found:</div>
          </div>
             
              <p>There are ${data.nibetDatas} points found with values between 0.1 and 1% of Nickel, </p>
              <table>
                <thead>
                  <tr>
      
                    <th>Area</th>
                    <th>Zone</th>
                    <th>XRF Ref.</th>
                    <th>Ni%</th>
              
                  </tr>
                </thead>
                <tbody>
                  ${tbodyContents}
                </tbody>
              </table>
            </body>
            </html>
          `;
    entireHTMLContent += tableHTMLs;

    let tbodyContentsz = '';

    // Iterate through the Reportarea data and generate table rows
    for (const rows of data.nilessData) {
      tbodyContentsz += `
              <tr>
                <td>${rows.area}</td>
                <td>${rows.zone}</td>
                <td>${rows.xrf_number}</td>
                <td>${rows.ni}</td>

              </tr>
            `;
    }


    const tableHTMLsz = `
            <html>
            <head>
              <style>
                table {
                  width: 100%;
                  border-collapse: collapse;
                }.red {
                  background-color: #e34234;
                } .orange-row {
                  background-color: #FFA500;
                }
                .yellow-row {
                  background-color: #F8D568;
                }
                .black-row {
                  background-color: #FFFFFF;
                }
                th, td {
                  border: 1px solid black;
                  padding: 8px;
                  text-align: center;
                }
                table {
                  margin-bottom: 20px; /* Add space after the table */
                }
                .summary-bar {
                  background-color: #f1f0f0; /* Grey background color */
                  width: 100vw; /* Cover the entire width of the page */
                  text-align: left;
                  color: #000000;
                  padding: 10px;
                  margin: 0;
                }
                .summary-text {
                  font-size: 20px;
                  font-weight: bold;
                }
              </style>
            </head>
            <body>
            <div class="summary-bar">
            <div class="summary-text"><0.05%-0.1% Ni parts found:</div>
          </div>
              
              <p>There are ${data.nilessDatas} points found having Ni under  between 0.05 and 0.1%, presented below:</p>
              <table>
                <thead>
                  <tr>
      
                    <th>Area</th>
                    <th>Zone</th>
                    <th>XRF Ref.</th>
                    <th>Ni%</th>
              
                  </tr>
                </thead>
                <tbody>
                  ${tbodyContentsz}
                </tbody>
              </table>
            </body>
            </html>
          `;
    entireHTMLContent += tableHTMLsz;

    let tbodyContentszz = '';

    // Iterate through the Reportarea data and generate table rows
    for (const rows of data.niverylessData) {
      tbodyContentszz += `
    <tr>
      <td>${rows.area}</td>
      <td>${rows.zone}</td>
      <td>${rows.xrf_number}</td>
      <td>${rows.ni}</td>

    </tr>
  `;
    }



    const tableHTMLszz = `
  <html>
  <head>
    <style>
      table {
        width: 100%;
        border-collapse: collapse;
      }.red {
        background-color: #e34234;
      } .orange-row {
        background-color: #FFA500;
      }
      .yellow-row {
        background-color: #F8D568;
      }
      .black-row {
        background-color: #FFFFFF;
      }
      th, td {
        border: 1px solid black;
        padding: 8px;
        text-align: center;
      }
      table {
        margin-bottom: 20px; /* Add space after the table */
      }
      .summary-bar {
        background-color: #f1f0f0; /* Grey background color */
        width: 100vw; /* Cover the entire width of the page */
        text-align: left;
        color: #000000;
        padding: 10px;
        margin: 0;
      }
      .summary-text {
        font-size: 20px;
        font-weight: bold;
      }
    </style>
  </head>
  <body>
  <div class="summary-bar">
  <div class="summary-text"><0.05% Ni parts found:</div>
</div>
    
    <p>There are ${data.niverylessDatas} points found having Ni under  <0.05%, presented below:</p>
    <table>
      <thead>
        <tr>

          <th>Area</th>
          <th>Zone</th>
          <th>XRF Ref.</th>
          <th>Ni%</th>
    
        </tr>
      </thead>
      <tbody>
        ${tbodyContentszz}
      </tbody>
    </table>
  </body>
  </html>
`;
    entireHTMLContent += tableHTMLszz;
   
    return entireHTMLContent
  }

 
  const handleCustomClick = async (data, imagearray) => {
    setMenuAnchor(null)
    
    let originalContent = document.body.innerHTML; // Store original content

    let entireHTMLContent = '';
    entireHTMLContent += `
          <html>
          <head>
            <title> ${data.frep[0].reference}</title>
            <style>
              /* Define your CSS styles here for formatting */
              .title-container {
                text-align: right; /* Align the container to the right */
                padding: 10px;
              }
              .title {
                text-align: right;
              }
              
              body {
                margin-top: 30;
                font-size: 16px; 
                font-family: Inter; 
                font-weight: 400;
                word-wrap: break-word
                /* font-family: Arial, sans-serif; */
                margin: 0; /* Remove default margins */
                padding: 0;

              }

              .header {
                text-align: center;
                
                color: black;
                padding: 5px;
                margin: 0;
              }
              .header p {
                text-align: center; /* Center-align the text within the <p> element */
              }
              .header img {
                display: block;
                margin: 0 auto; /* Center the image horizontally */
                width: 200px; /* Adjust the width as needed */
                max-height: 200px; /* Adjust the height as needed */
              }
              .section-container {
                margin-top: 50px; /* Add a 10px top margin to create a gap */
              }
              .report-title {
                text-align: center;
                margin-top: -20px;
                margin-bottom: 0; /* Set margin-bottom to 0 */
                padding-bottom: 0;
              }
              .company {
                text-align: center;
              }
              .info-container {
                display: flex;
                flex-direction: column;
              }
              .info-item {
                margin-bottom: 10px;
                
              }
              .info-item-in {
                margin-bottom: 10px;
                display: flex;
              }
              .info-label {
                width: 200px; /* Adjust the width as needed */
                display: inline-block;
              }
              .info-label_in {
               
                margin-bottom: 5px;
              }
              .info-label_in + p {
                margin-top: 5px; /* Adjust the value as needed */
              }
              .info {
                margin-left: 10px;
              }
              .info_in {
                margin-left: 10px;
                white-space: pre-wrap;
                display: inline-block;
                max-width: 400px; 
              }
              .section {
                margin-top: 20px;
                padding: 10px;
                line-height: 20px;
                border: 1px solid #ddd;
              }
              .section-headings {
                text-align: left;
                padding: 10px;
                margin: 0;
              }
              .section-heading {
               
                font-size: 16px;
               
              }

              .section-content {
                font-size: 14px;
              }
              .next-page {
                page-break-before: always;
              
              }
              /* Add more styles as needed */


    

              .summary {
                height: 50vh; /* Set the height of the first page to cover the entire viewport height */
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }
              .footer-content {
                position: absolute;
                bottom: 10;
                width: 100%;
                text-align: center;
              
                color: black;
                padding: 3px;
              }
              .footer-content img {
                max-width: 100px; /* Adjust the width as needed */
                max-height: 100px; /* Adjust the height as needed */
              }
              .summary-bar {
                margin-top: 100px;
                background-color: #F2F4F7; /* Grey background color */
                width: 100vw; /* Cover the entire width of the page */
                text-align: left;
                color: #000000;
                padding: 10px;
                margin: 0;
              }
              .summary-text {
                font-size: 20px;
                font-weight: bold;
              }
              .red {
                background-color: #FF0000;
              }
              .orange-row {
                background-color: #FFA500;
              }
              .oran-row {
                background-color: #FFA500;
                color: red;
              }
              .yellow-row {
                background-color: #F8D568;
              }
              .black-row {
                background-color: #FFFFFF;
              }
              .Vermilion {
                background-color: #e34234;
              }
              
             
            </style>
          </head>
          <body>
         

      
            
           

        <div class="header">
        <!-- Add company logo and address here -->
        <img src="${sglogo}" alt="Company Logo" />
        <p>Saint-Gobain India Pvt Ltd. (Research and Development) <br>
        IIT Madras Research Park, NO:1 FA, Phase-II, C block, Kanagam Road, Tharamani, Chennai-600 113, Tamil Nadu, India.</p>
      </div>
            
            
            
          </body>
          </html>
        `;
   

    entireHTMLContent += `
      
        
          <h1 class="report-title">${data.repdata[0].report_name}</h1>
        
        <div class="summary-bar">
          <div class="summary-text">Flashmemo:</div>
          </div>
          <p>Saint Gobain India Private Limited (Research and Development)</p>
          
          <div class="info-container">
            <div class="info-item-in">
              <span class="info-label">FROM:</span> <span class="info_in">${data.frep[0].from}</span>
            </div>
            <div class="info-item-in">
              <span class="info-label">TO:</span> <span class="info_in">${data.frep[0].to}</span>
            </div>
            <div class="info-item-in">
              <span class="info-label">COPY:</span> <span class="info_in">${data.frep[0].copy}</span>
            </div>
            <div class="info-item-in">
              <span class="info-label">DATE:</span> <span class="info_in">${data.frep[0].date}</span>
            </div>
            <div class="info-item-in">
              <span class="info-label">REFERENCE:</span> <span class="info_in">${data.frep[0].reference}</span>
            </div>
            <div class="info-item-in">
            <span class="info-label">CONFIDENTIALITY:</span> <span class="info_in">${data.frep[0].confidentiality}</span>
          </div>
          </div>
       
        
        `;

    entireHTMLContent += `
                <div class="summary-bar">
                <div class="summary-text">Title of the Memo: </div>
                </div>
            
                <p>${data.objective[0].title}</p>
                <div class="summary-bar">
                <div class="summary-text">N GIE: </div>
                </div>
                <p>${data.objective[0].n_gie}</p>
                <div class="summary-bar">
                <div class="summary-text">Objective: </div>
                </div>
            
                <p>${data.objective[0].objective}</p>
                <div class="summary-bar">
                <div class="summary-text">Main Conclusion: </div>
                </div>
                <p>${data.objective[0].main_conclusion}</p>
                <div class="summary-bar">
                <div class="summary-text">Next Step: </div>
                </div>
            
                <p>${data.objective[0].next_step}</p>
    
            <div class="summary-bar">
<div class="summary-text">SUMMARY</div>
</div>
<div class="section-headings">
<ol>

<li><div class="section-heading">Flashmemo</div></li>
<li><div class="section-heading">Objective</div></li>
<li><div class="section-heading">Introduction</div></li>
<li><div class="section-heading">Overview</div></li>
<li><div class="section-heading">Work Description</div></li>
<li><div class="section-heading">Result</div></li>
<li><div class="section-heading">Area Description</div></li>
<li><div class="section-heading">Conclusion</div></li>
</ol>
</div>
            <div class="summary-bar">
            <div class="summary-text">Introduction: </div>
            </div>
        
            <p>${data.repdata[0].introduction}</p>
            <div class="summary-bar">
            <div class="summary-text">Overview: </div>
            </div>
          
     
            <p>${data.repdata[0].overview}</p>

            <div class="summary-bar">
            <div class="summary-text">Work Description: </div>
            </div>
          
          
            <p>${data.repdata[0].workDescription}</p>
            <div class="summary-bar">
            <div class="summary-text"> Colours code %Ni  </div>
            </div>
            <p> According to the Ni% content, there is a Colours Code of priorities: </p>
      
            <table>
              <thead>
                <tr>
                  <th> %Ni </th>
                  <th>Priority</th>
                 
                </tr>
              </thead>
              <tbody>
              <tr>
              <td  class = "Vermilion"> > 1%</td>
              <td>1</td>
              </tr>
              <tr>
              <td  class = "oran-row" > 0.1% - 1% </td>
              <td>2</td>
              </tr>
              <tr>
              <td  class = "yellow-row" > 0.05% - 0.1% </td>
              <td> Acceptable </td>
              </tr>
              <tr>
              <td  class = "black-row" > <0.05% </td>
              <td> OK </td>
              </tr>
              </tbody>
            </table>
          
            <p>
            <strong style="text-decoration: underline; ">
           Not only Ni % define an action plan
            </strong>
            (replacement or protection the high Nickel content equipment/parts), but
            <strong style="text-decoration: underline; ">
           also abrasion level for each piece (g/year).
             </strong>
        </p>
      
        <div class="summary-bar">
            <div class="summary-text">Result: </div>
            </div>
          
     
            <p>${data.repdata[0].result}</p>
            <div class="summary-bar">
            <div class="summary-text">Area Description: </div>
            </div>
          
           
        `;
    let sectionCounter = 0;


    for (const subArray of data.arealist) {
      if (subArray.length === 0) {
        // If subArray is empty, move on to the next iteration
        continue;
      }
      sectionCounter++;
    

      entireHTMLContent += `
          <h3>
    <strong style="text-decoration: underline; text-transform: uppercase;">
    ${sectionCounter}. ${subArray[0].area}
    </strong>
</h3>

          
      
// //             <p>${data.flashData[subArray[0].id]}</p>
          
          `;

      let tbodyContentt = ''; // Initialize tbody content for the section

      const calculateRowspan = (currentIndex) => {
        let rowspan = 1;
        while (currentIndex + rowspan < subArray.length &&
          subArray[currentIndex].area === subArray[currentIndex + rowspan].area) {
          rowspan++;
        }
        return rowspan;
      };
      let rowIndex = 0
      for (const row of subArray) {
       
        let rowClass = ''; // Initialize an empty class string

        if (parseFloat(row.ni) > 1) {
          rowClass = 'red';
        } else if (parseFloat(row.ni) >= 0.1 && parseFloat(row.ni) < 1) {
          rowClass = 'orange-row'; // Orange background for ni >= 0.1 and < 1
        } else if (parseFloat(row.ni) >= 0.05 && parseFloat(row.ni) < 0.1) {
          rowClass = 'yellow-row'; // Yellow background for ni >= 0.05 and < 0.1%
        } else {
          rowClass = 'black-row'; // Black background for ni < 0.05%
        }


        //  const reportimg= getImageData(imagePath)
        //  console.log("reportimghandleviewsclcik",reportimg)
        //  const updatedresponse=`data:image/jpeg;base64,`+reportimg.data;
        const imageSize = 200;

        const rowspan = calculateRowspan(rowIndex);
        const match = imagearray.find(obj2 => obj2.path === row.path);
       
        // Generate table rows
        tbodyContentt += `
              <tr>
              ${rowIndex === 0 ? `<td  rowspan="${rowspan}">${row.area}</td>` : ''}
    <td class="${rowClass}">${row.zone}</td>
    <td class="${rowClass}">${row.xrf_number}</td>
    <td class="${rowClass}">${row.ni}</td>
    <td class="${rowClass}">${row.remark}<br>
    ${row.comments}</td>
    <td style="width: ${imageSize}px; height: ${imageSize}px; overflow: hidden;">
    <img src="${match.image}" alt="image" style="width: 100%; height: auto;">
  </td>
</tr>`;
        rowIndex++;
      };


      // Use the tbodyContent in your HTML
      const areatableHTML = `
          <html>
          <head>
            <style>
            img {
              max-width: 100%;
              max-height: 100%;
            }
              table {
                width: 100%;
                border-collapse: collapse;
              }
              .red {
                background-color: #e34234;
              }
              .orange-row {
                background-color: #FFA500;
              }
              .yellow-row {
                background-color: #F8D568;
              }
              .black-row {
                background-color: #FFFFFF;
              }
              th, td {
                border: 1px solid black;
                padding: 0;
                text-align: center;
              }
            </style>
          </head>
          <body>
            <table>
              <thead>
                <tr>
                  <th>Area</th>
                  <th>Zone</th>
                  <th>XRF Ref.</th>
                  <th>Ni%</th>
                  <th>Remark <br> Comments</th>
                  <th>Images</th>
                </tr>
              </thead>
              <tbody>
                ${tbodyContentt}
              </tbody>
            </table>
          </body>
          </html>
          `;

      entireHTMLContent += areatableHTML;
    };

    // Now, 'entireHTMLContent' contains the HTML code with merged rows for the same area.
    




    entireHTMLContent += `

                        <div class="summary-bar">
                        <div class="summary-text">Conclusion: </div>
                        </div>

           
          
            
         

        
           
           
          `;

    const ConclusiontableHTML = `
          <html>
          <head>
            <style>
              table {
                width: 100%;
                border-collapse: collapse;
              }.red {
                background-color: #e34234;
              } .orange-row {
                background-color: #FFA500;
              }
              .yellow-row {
                background-color: #F8D568;
              }
              .black-row {
                background-color: #FFFFFF;
              }
              th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: center;
              }
              table {
                margin-bottom: 20px; /* Add space after the table */
              }
            </style>
          </head>
          <body>
            <h4>Relevant values of Nickel</h4>
            <table>
              <thead>
                <tr>
                  <th>Priority</th>
                  <th>% of Ni</th>
                  <th>No. of points</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                <td>1</td>
                <td>>1.0%</td>
                <td class="red">${data.greater}</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>0.1-1.0%</td>
                  <td class="orange-row">${data.inBetween}</td>
                </tr>
                <tr>
                <td>Acceptable</td>
                <td>0.05-0.1%</td>
                <td class="yellow-row">${data.inBet}</td>
                </tr>
                <tr>
                <td>OK</td>
                <td><0.05%</td>
                <td class="black-row">${data.lesser}</td>
                </tr>
              </tbody>
            </table>
          </body>
          </html>
        `;

    entireHTMLContent += ConclusiontableHTML;



    const htmlContent = `<!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Simple Pie Chart</title>
          <style>
            canvas {
              display: block;
              margin: 10px auto; /* Reduced margin */
            }
            .legend {
              display: flex;
              justify-content: center;
              margin-top: 10px;
            }
            .legend-item {
              display: flex;
              align-items: center;
              margin-right: 20px;
            }
            .legend-item span {
              display: inline-block;
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
          </style>
        </head>
        <body>
          <canvas id="myPieChart" width="200" height="200"></canvas>
          <div class="legend" id="legend"></div>
        
          <script>
            // Get the canvas element and its context
            var canvas = document.getElementById('myPieChart');
            var ctx = canvas.getContext('2d');
        
            // Define data for the pie chart
            var data = [${data.greater}, ${data.inBetween}, ${data.inBet}, ${data.lesser}]; // Values for each segment
            var colors = ['#e34234', '#FFA500', '#F8D568', '#4c4cff']; // Colors for each segment
            var legends = ['>1.0%', '0.1-1.0%', '0.05-0.1%', '<0.05%']; // Legends for each segment
        
            // Function to draw a pie chart
            function drawPieChart(data, colors, legends, context, canvas) {
              var total = data.reduce((acc, value) => acc + value, 0);
              var startAngle = 0;
              var centerX = canvas.width / 2;
              var centerY = canvas.height / 2;
              var radius = Math.min(canvas.width, canvas.height) / 2;
        
              for (var i = 0; i < data.length; i++) {
                var sliceAngle = (2 * Math.PI * data[i]) / total;
        
                // Draw the pie slice
                context.fillStyle = colors[i];
                context.beginPath();
                context.moveTo(centerX, centerY);
                context.arc(centerX, centerY, radius, startAngle, startAngle + sliceAngle);
                context.closePath();
                context.fill();
        
                startAngle += sliceAngle;
              }
        
              // Draw legend below the chart
              var legendHTML = '';
              for (var i = 0; i < legends.length; i++) {
                legendHTML += '<div class="legend-item"><span style="background-color:' + colors[i] + '"></span>' + legends[i] + '</div>';
              }
              document.getElementById('legend').innerHTML = legendHTML;
            }
        
            // Call the function to draw the pie chart
            drawPieChart(data, colors, legends, ctx, canvas);
          </script>
        </body>
        </html>`;

    entireHTMLContent += htmlContent;

    entireHTMLContent += `
       
       <p>${data.repdata[0].conclusion}</p>
      `;

    let tbodyContent = '';

    // Iterate through the Reportarea data and generate table rows
    for (const rows of data.niData) {
      let rowClass = ''; // Initialize an empty class string

      if (parseFloat(rows.ni) > 1) {
        rowClass = 'red';
      } else if (parseFloat(rows.ni) > 0.1 && parseFloat(rows.ni) <= 1) {
        rowClass = 'orange-row'; // Orange background for ni >= 1% and < 1
      } else if (parseFloat(rows.ni) > 0.05 && parseFloat(rows.ni) <= 0.1) {
        rowClass = 'yellow-row'; // Yellow background for ni >= 0.05% and < 0.1%
      } else {
        rowClass = 'black-row'; // Black background for ni < 0.05%
      }
      tbodyContent += `
            <tr>
                <td>${rows.area}</td>
                <td>${rows.zone}</td>
                <td>${rows.xrf_number}</td>
                <td class="${rowClass}">${rows.ni}</td>

              </tr>
            `;
    }

    const tableHTML = `
            <html>
            <head>
              <style>
                table {
                  width: 100%;
                  border-collapse: collapse;
                }.red {
                  background-color: #e34234;
                } .orange-row {
                  background-color: #FFA500;
                }
                .yellow-row {
                  background-color: #F8D568;
                }
                .black-row {
                  background-color: #FFFFFF;
                }
                th, td {
                  border: 1px solid black;
                  padding: 8px;
                  text-align: center;
                }
                table {
                  margin-bottom: 20px; /* Add space after the table */
                }
                .summary-bar {
                  background-color: #f1f0f0; /* Grey background color */
                  width: 100vw; /* Cover the entire width of the page */
                  text-align: left;
                  color: #000000;
                  padding: 10px;
                  margin: 0;
                }
                .summary-text {
                  font-size: 20px;
                  font-weight: bold;
                }
              </style>
            </head>
            <body>
            <div class="summary-bar">
            <div class="summary-text">>1% Ni parts found:</div>
          </div>
             
              <p>There are ${data.niDatas} points found having Ni content >1%, presented below:</p>
              <table>
                <thead>
                  <tr>
      
                    <th>Area</th>
                    <th>Zone</th>
                    <th>XRF Ref.</th>
                    <th>Ni%</th>
              
                  </tr>
                </thead>
                <tbody>
                  ${tbodyContent}
                </tbody>
              </table>
            </body>
            </html>
          `;
    entireHTMLContent += tableHTML;

    let tbodyContents = '';

    // Iterate through the Reportarea data and generate table rows
    for (const rows of data.nibetData) {
      let rowClass = ''; // Initialize an empty class string

      if (parseFloat(rows.ni) > 1) {
        rowClass = 'red';
      } else if (parseFloat(rows.ni) > 0.1 && parseFloat(rows.ni) <= 1) {
        rowClass = 'orange-row'; // Orange background for ni >= 1% and < 1
      } else if (parseFloat(rows.ni) > 0.05 && parseFloat(rows.ni) <= 0.1) {
        rowClass = 'yellow-row'; // Yellow background for ni >= 0.05% and < 0.1%
      } else {
        rowClass = 'black-row'; // Black background for ni < 0.05%
      }
      tbodyContents += `
              <tr>
                <td>${rows.area}</td>
                <td>${rows.zone}</td>
                <td>${rows.xrf_number}</td>
                <td  class="${rowClass}">${rows.ni}</td>

              </tr>
            `;
    }


    const tableHTMLs = `
            <html>
            <head>
              <style>
                table {
                  width: 100%;
                  border-collapse: collapse;
                }.red {
                  background-color: #e34234;
                } .orange-row {
                  background-color: #FFA500;
                }
                .yellow-row {
                  background-color: #F8D568;
                }
                .black-row {
                  background-color: #FFFFFF;
                }
                th, td {
                  border: 1px solid black;
                  padding: 8px;
                  text-align: center;
                }
                table {
                  margin-bottom: 20px; /* Add space after the table */
                }
                .summary-bar {
                  background-color: #f1f0f0; /* Grey background color */
                  width: 100vw; /* Cover the entire width of the page */
                  text-align: left;
                  color: #000000;
                  padding: 10px;
                  margin: 0;
                }
                .summary-text {
                  font-size: 20px;
                  font-weight: bold;
                }
              </style>
            </head>
            <body>
            <div class="summary-bar">
            <div class="summary-text">>0.1% - 1% Ni parts found:</div>
          </div>
             
              <p>There are ${data.nibetDatas} points found with values between 0.1 and 1% of Nickel, </p>
              <table>
                <thead>
                  <tr>
      
                    <th>Area</th>
                    <th>Zone</th>
                    <th>XRF Ref.</th>
                    <th>Ni%</th>
              
                  </tr>
                </thead>
                <tbody>
                  ${tbodyContents}
                </tbody>
              </table>
            </body>
            </html>
          `;
    entireHTMLContent += tableHTMLs;

    let tbodyContentsz = '';

    // Iterate through the Reportarea data and generate table rows
    for (const rows of data.nilessData) {
      tbodyContentsz += `
              <tr>
                <td>${rows.area}</td>
                <td>${rows.zone}</td>
                <td>${rows.xrf_number}</td>
                <td>${rows.ni}</td>

              </tr>
            `;
    }


    const tableHTMLsz = `
            <html>
            <head>
              <style>
                table {
                  width: 100%;
                  border-collapse: collapse;
                }.red {
                  background-color: #e34234;
                } .orange-row {
                  background-color: #FFA500;
                }
                .yellow-row {
                  background-color: #F8D568;
                }
                .black-row {
                  background-color: #FFFFFF;
                }
                th, td {
                  border: 1px solid black;
                  padding: 8px;
                  text-align: center;
                }
                table {
                  margin-bottom: 20px; /* Add space after the table */
                }
                .summary-bar {
                  background-color: #f1f0f0; /* Grey background color */
                  width: 100vw; /* Cover the entire width of the page */
                  text-align: left;
                  color: #000000;
                  padding: 10px;
                  margin: 0;
                }
                .summary-text {
                  font-size: 20px;
                  font-weight: bold;
                }
              </style>
            </head>
            <body>
            <div class="summary-bar">
            <div class="summary-text"><0.05%-0.01 Ni parts found:</div>
          </div>
              
              <p>There are ${data.nilessDatas} points found having Ni between 0.05% and 0.1% presented below:</p>
              <table>
                <thead>
                  <tr>
      
                    <th>Area</th>
                    <th>Zone</th>
                    <th>XRF Ref.</th>
                    <th>Ni%</th>
              
                  </tr>
                </thead>
                <tbody>
                  ${tbodyContentsz}
                </tbody>
              </table>
            </body>
            </html>
          `;
    entireHTMLContent += tableHTMLsz;


    let tbodyContentszz = '';

    // Iterate through the Reportarea data and generate table rows
    for (const rows of data.niverylessData) {
      tbodyContentszz += `
    <tr>
      <td>${rows.area}</td>
      <td>${rows.zone}</td>
      <td>${rows.xrf_number}</td>
      <td>${rows.ni}</td>

    </tr>
  `;
    }


    const tableHTMLszz = `
  <html>
  <head>
    <style>
      table {
        width: 100%;
        border-collapse: collapse;
      }.red {
        background-color: #e34234;
      } .orange-row {
        background-color: #FFA500;
      }
      .yellow-row {
        background-color: #F8D568;
      }
      .black-row {
        background-color: #FFFFFF;
      }
      th, td {
        border: 1px solid black;
        padding: 8px;
        text-align: center;
      }
      table {
        margin-bottom: 20px; /* Add space after the table */
      }
      .summary-bar {
        background-color: #f1f0f0; /* Grey background color */
        width: 100vw; /* Cover the entire width of the page */
        text-align: left;
        color: #000000;
        padding: 10px;
        margin: 0;
      }
      .summary-text {
        font-size: 20px;
        font-weight: bold;
      }
    </style>
  </head>
  <body>
  <div class="summary-bar">
  <div class="summary-text"><0.05% Ni parts found:</div>
</div>
    
    <p>There are ${data.niverylessDatas} points found having Ni under  <0.05%, presented below:</p>
    <table>
      <thead>
        <tr>

          <th>Area</th>
          <th>Zone</th>
          <th>XRF Ref.</th>
          <th>Ni%</th>
    
        </tr>
      </thead>
      <tbody>
        ${tbodyContentszz}
      </tbody>
    </table>
  </body>
  </html>
`;
    entireHTMLContent += tableHTMLszz;
    return entireHTMLContent
  }

  const handleDeleteClick = () => {
    // Show the delete confirmation dialog
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = () => {
    onDeleteClick(reportData.id);
    // Hide the delete confirmation dialog
    setShowDeleteConfirmation(false);
  };

  const cancelDelete = () => {
    // Hide the delete confirmation dialog
    setShowDeleteConfirmation(false);
  };

  const getPlantReports = (plantid) => {
    let url = ApiURL + `/show_report_plant/${plantid}`;

    axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,

      }
    }
    ).then((response) => {
      const firstPlantName = response.data[0]?.plantName || '';
      setPlantName(firstPlantName);


    }).catch((error) => {
      setPlantName('');
    });


  }


  function isFieldEmpty(dataObject) {
    for (const key in dataObject) {
      if (dataObject.hasOwnProperty(key)) {
        const value = dataObject[key];
        if (typeof value === 'string' && value.trim() === '') {
          return true; // Field is empty
        }
      }
    }
    return false; // No empty fields foun
  }

  const isEmpty = isFieldEmpty(reportData);
  function capitalizePlantName(name) {
    if (name.length <= 3) {
      // If the name has three or fewer letters, convert it to uppercase
      return name.toUpperCase();
    } else {
      // If the name has more than three letters, capitalize the first letter and make the rest lowercase
      return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }
  }

  // const getDateFromString = (dateString) => {
  //   return dateString ? new Date(dateString.split("T")[0]) : null;
  // };

  // const dateFromStr = getDateFromString(reportData.fromdate);
  // const dateToStr = getDateFromString(reportData.todate);

  let increasedFromDateStr = reportData.formatted_from_date;
  let increasedToDateStr = reportData.formatted_to_date;

  // if (dateFromStr && dateToStr) {
  //   const currentFromDate = new Date(dateFromStr);
  //   const currentToDate = new Date(dateToStr);

  //   // Increase the date by one day
  //   currentFromDate.setDate(currentFromDate.getDate() + 1);
  //   currentToDate.setDate(currentToDate.getDate() + 1);

  //   // Format the increased date back to the desired format
  //   increasedFromDateStr = currentFromDate.toISOString().split('T')[0];
  //   increasedToDateStr = currentToDate.toISOString().split('T')[0];
  // }



  const openActionTakenModal = () => {

    setActionTakenModalOpen(true);

    const plantId = reportData.plant_id;
    
    let url = ApiURL + `/show_reports_action_user/${plantId}`;
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,

        }
      }
      )
      .then((response) => {

        setActionTakenByOptions(response.data);
      })
      .catch((error) => {
        setActionTakenByOptions([]);
      });

  };
  const closeActionTakenModal = () => {
    setActionCommentSelect('')
    setSelectedActionDate('')
    setSelectedActionTakenBy([])
    setSelectAllAction(false)
    setActionTakenModalOpen(false);
  };

  const handleSaveActionTaken = () => {
    const reportId = reportData.id;
    const data = {
      action_date: selectedActionDate,
      action_by_filter: selectedActionTakenBy.map((option) => ({ name: option.name, id: option.id })),
      action_comment: actionComment,

    };

    axios.post(ApiURL + `/create_action/${reportId}`, data, {
      headers: {
        Authorization: `Bearer ${access_token}`,

      }
    }
    )
      .then(response => {
        // Handle success (if needed)
        

        // Close the modal

      })
      .catch(error => {
        // Handle error (if needed)
        console.error('Error saving action taken:', error);

        // Close the modal (you may want to handle errors differently)

      });

    closeActionTakenModal();
  };
  const handleActionDateChange = (event) => {
    const dateTimeString = event.target.value; // Use default value if date is invalid
    const formattedDate = moment.utc(dateTimeString).format("YYYY-MM-DD");
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format

    if (formattedDate > today) {
      setErrorMessageActionDate("You cannot select a future date.");
    } else {
      setErrorMessageActionDate('');
      setSelectedActionDate(formattedDate);


    }
  }
  const today = new Date().toISOString().split('T')[0];
  const handleActionSelectAllChange = (event) => {
    if (event.target.checked) {
      setSelectedActionTakenBy([...actionTakenByOptions]);


    } else {

      setSelectedActionTakenBy([]);
    }
    setSelectAllAction(event.target.checked);

  };


  const handleActionOptionChange = (event, option) => {

    const optionId = option.id; // Assuming that each option has an 'id' property

    if (event.target.checked) {

      setSelectedActionTakenBy((prevSelected) => [...prevSelected, option]);

    } else {


      setSelectedActionTakenBy((prevSelected) =>
        prevSelected.filter((selectedOption) => selectedOption.id !== optionId)
      );
    }
    setSelectAllAction(false);

  };


  const renderSelectedActionOptions = (selected) => {
    if (!actionTakenByOptions || actionTakenByOptions.length === 0) {
      return "Select All Action";
    }

    if (selected.includes("selectAllAction")) {
      return "Select All Action";
    }

    const selectedValues = selected.map((option) => option.name);
    return selectedValues.join(", ");
  };


  const handleCommentsChange = (event) => {

    setActionComment(event.target.value);

    setActionCommentSelect(event.target.value)

  };


 
  return (
    <div >
      <Card style={isMobileView ? cardMobileStyle : cardStyle} variant="outlined">
        <CardContent style={isMobileView ? contentStyleMobile : contentStyle}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ marginBottom: '10px' }} >
              <IconButton aria-label="file icon">
                <img src={fileIcon} alt="File Icon" />
              </IconButton>
            </div>
            <div style={topRightCornerStyle}>
              {isEmpty ? (

                <Chip label="Draft" style={{ backgroundColor: '#FCA326', color: '#FFF', width: '100px' }} />

              ) : (

                <Chip label="Completed" style={{ backgroundColor: '#2ECC71', color: '#FFF', width: '100px' }} />

              )}
              <IconButton aria-label="more" onClick={handleOpenMenu}>
                <MoreVertIcon style={{ color: '#000000' }} />
              </IconButton>
              <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleCloseMenu}>
                {userDet.role_id !== 2 && <MenuItem onClick={handleEditClick}>Edit</MenuItem>}

                {isEmpty ? null : reportData.year ? <MenuItem onClick={handleViewsClick}>View</MenuItem> : <MenuItem onClick={handleClick}>View</MenuItem>}
                {userDet.role_id !== 2 && <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>}

                <MenuItem onClick={handleInfoClick}>Info</MenuItem>
                {isEmpty ? null : reportData.year ? <MenuItem onClick={handleExcelClick}>Excel Download</MenuItem> : <MenuItem onClick={handleExcelcustomClick}>Excel Download</MenuItem>}
                {/* {isEmpty ? null :reportData.year ? <MenuItem onClick={openActionTakenModal}>Action Taken</MenuItem>:<MenuItem onClick={openActionTakenModal}>Action Taken</MenuItem>} */}
                {isEmpty ? null : <MenuItem onClick={openActionTakenModal}>Action Taken</MenuItem>}

              </Menu>


              <Dialog open={showDeleteConfirmation} onClose={cancelDelete}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                  <DialogContentText>Are you sure you want to delete this report?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={cancelDelete} color="primary" >
                    No
                  </Button>
                  <Button onClick={confirmDelete} color="primary" variant="contained">
                    Yes,I Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>



          <div style={{ alignSelf: 'flex-start', width: '100%' }}>


            <Typography variant="subtitle2" style={{ color: '#161616', fontSize: '16px', fontWeight: '600', wordWrap: 'break-word' }}>
              {capitalizePlantName(plantName) || ''}/

              {reportData.year ? reportData.year : `${increasedFromDateStr} to ${increasedToDateStr}`}/

              {reportData.report_name}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flex: 1, overflow: 'hidden', width: '100%' }}>
              <div>
                <Typography variant="subtitle2" style={{ color: '#808080', fontWeight: 600, fontSize: '16px' }}>
                  <span style={{ fontWeight: 500, color: '#808080' }}>Created by</span> {reportData.created_by_name}
                </Typography>
                {reportData.updated_by_name && (
                  <Typography variant="subtitle2" style={{ color: '#808080', fontWeight: 600, fontSize: '16px' }}>
                    <span style={{ fontWeight: 500, color: '#808080' }}>Edited by</span> {reportData.updated_by_name}
                  </Typography>
                )}
              </div>

              <div style={{ textAlign: 'right', justifyContent: 'space-between' }}>
                <Typography variant="subtitle2" style={{ color: '#808080', fontSize: '16px' }}>
                  {reportData.created_on?.split('T')[0] || ''}
                </Typography>
                {reportData.updated_on && (
                  <Typography variant="subtitle2" style={{ color: '#808080', fontSize: '16px' }}>
                    {reportData.updated_on?.split('T')[0] || ''}
                  </Typography>
                )}

              </div>

            </div>
          </div>

        </CardContent>
      </Card>
      <Dialog open={infoModalOpen} onClose={closeInfoModal} style={{ height: '100%', width: '100%' }}>
        <DialogTitle style={{ fontWeight: '600', fontSize: '20px' }}> Action History</DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>



          <div style={{ minWidth: '530px', height: '100%', paddingTop: '20px' }}>

            <div className="vertical-timeline">
              {actionHistory.map((action, index) => (
                <div className="timeline-item" key={index}>

                  <div className="timeline-content">
                    <div className="left-content">
                      <Typography variant="subtitle2" style={{ color: 'textSecondary', fontSize: '16px', minWidth: '100px' }}>
                        {action.formattedIncreasedDate || ''}
                      </Typography>
                    </div>
                    <div className="timeline-circle" />
                    <div className="timeline-connector" />
                    <div className="right-content">
                      <Typography variant="subtitle2" style={{ color: 'textSecondary', fontSize: '16px' }}>
                        <span style={{ fontWeight: 'bold', color: '#333333' }}>Comments:</span>
                      </Typography>
                      <div>
                        {action.action_comment}
                      </div>
                      <Typography variant="subtitle2" style={{ color: 'textSecondary', fontSize: '16px' }}>
                        <span style={{ fontWeight: 'bold' }}>Action Taken By:</span>
                      </Typography>
                      <div >
                        {action.action_by_filter ? action.action_by_filter.map(item => JSON.parse(item).name).join(', ') : ''}
                      </div>
                    </div>
                  </div>

                </div>
              ))}
            </div>


          </div>



        </DialogContent>
        <DialogActions>
          <Button onClick={closeInfoModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={actionTakenModalOpen} onClose={closeActionTakenModal} fullWidth maxWidth="xs">
        <DialogTitle style={{ paddingBottom: 0, fontSize: "18px", fontWeight: 600 }}>Action Taken</DialogTitle>
        <DialogContent>
          <hr style={{ margin: '5px 0', borderColor: '#0000001A' }} />
          <div>
            <InputLabel style={{ color: '#333333', paddingBottom: 0, fontSize: "16px", fontWeight: 600, marginTop: 16 }}>Action Taken Date</InputLabel>
            <FormControl fullWidth style={{ marginBottom: 16 }}>
              <TextField
                fullWidth
                id="ActionDate"
                type="date"
                value={selectedActionDate}
                onChange={handleActionDateChange}
                size="small"
                className="input-nis"
                inputProps={{ max: today }}
              />
            </FormControl>
          </div>
          <InputLabel style={{ color: '#333333', paddingBottom: 0, fontSize: "16px", fontWeight: 600 }}>Action Taken By</InputLabel>
          <FormControl fullWidth style={{ marginBottom: 16 }}>

            <Select
              multiple
              value={selectedActionTakenBy}
              onChange={handleActionOptionChange}
              className='select-nis'
              size="small"
              renderValue={renderSelectedActionOptions}

            >
              <MenuItem key="selectAllAction" value="selectAllAction">
                <Checkbox checked={selectAllAction} onChange={handleActionSelectAllChange} />
                <ListItemText primary="Select All Action" />
              </MenuItem>
              {actionTakenByOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox checked={selectedActionTakenBy.includes(option)}
                    onChange={(event) => handleActionOptionChange(event, option)} />

                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputLabel style={{ color: '#333333', paddingBottom: 0, fontSize: "16px", fontWeight: 600 }}>Comments</InputLabel>
          <FormControl fullWidth style={{ marginBottom: 16 }}>
            <TextField
              fullWidth
              multiline
              value={actionCommentselect}
              onChange={handleCommentsChange}
              rows={5}
              className="input-nis"
              InputProps={{
                className: 'input-nis',
              }}
              size="small"

            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" style={{ marginRight: '8px', color: '#203468', borderColor: '#002060' }} onClick={closeActionTakenModal} color="primary">
            Cancel
          </Button>
          <Button variant="contained" style={{ backgroundColor: '#002060' }} onClick={handleSaveActionTaken} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>




    </div>

  );
};
export default AnnualReportCard;